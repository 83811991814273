import React from 'react';
import { Text, TextVariant } from '@naf/text';
import { Grid } from '@naf/grid';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import { Layout } from '../../components/layout/Layout';
import { SiteStructureType } from '../../../../types/siteStructureType';
import { SectionAppType } from '../../../../types/CategoryAppType';
import { SectionBlocks } from '../../components/SectionBlocks';
import { ArticleCard } from './components/ArticleCard';
import { MainEntryCard } from '../../components/entry-cards/MainEntryCard';
import { SecondaryEntryCard } from '../../components/entry-cards/SecondaryEntryCard';
import { NumberedLinkCard } from '../../components/entry-cards/NumberedLinkCard';
import { FeaturedBenefitBlock } from '../../components/SectionBlocks/blocks/FeaturedBenefitBlocks/FeaturedBenefitBlock';
import { TextAndImageBlock } from '../../components/SectionBlocks/blocks/TextAndImageBlock';
import { CampaignBlock } from '../../components/block-content/components/types/components/CampaignBlock';
import { BenefitsByTag } from './components/benefitsByTag';
import ErrorBoundary from '../../components/error-component/ErrorBoundary';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';
import { useResponsiveImage } from '../../hooks/useResponsiveImage';
import { NotFound } from '../../loadable-elements/NotFound';
import * as S from './Styles';

interface Props {
  section: SiteStructureType;
  appData: SectionAppType;
}

// NB! This page should only be referenced via PageHandler
export const SectionPage = (
  {
    section: { slug, name: sectionName, ingress, seoConfig, image },
    appData: {
      name: appName,
      properties: {
        sections,
        article1,
        article2,
        benefitTag,
        campaignBlock,
        mainEntries,
        secondaryEntries,
        listSection,
        featuredBenefitCampaign,
        textAndImage,
      },
    },
  }: Props = { section: { _id: '', id: '', name: '', slug: '' }, appData: { name: 'sectionObject', properties: {} } },
) => {
  const windowPath = useWindowLocation();

  const mainImage = useResponsiveImage({
    imageId: image?.publicId || '',
    initialDimensions: { width: 400, height: 448 },
  });

  if (slug && slug !== 'nafno') {
    const seoTitle = seoConfig?.title ? seoConfig.title : sectionName;
    if (appName !== 'sectionObject') return <NotFound />;
    const mainEntriesCol = mainEntries && mainEntries.length < 4 ? '4' : '3';

    return (
      <Layout
        title={sectionName}
        description={ingress || seoConfig?.introduction || ''}
        url={windowPath}
        gtmArgs={{
          page_type: 'SectionPage',
          pageCategory: slug,
          contentId: sectionName,
        }}
        seoTitle={seoTitle}
        seoDescription={seoConfig?.introduction}
        internalSearchMetaData={{ cardType: InternalSearchCardType.CategoryApp }}
      >
        <S.StyledSectionHeader
          title={sectionName}
          ingress={ingress || undefined}
          imageOrVideo={
            mainImage && (
              <S.StyledAdvancedImage cldImg={mainImage} alt={image.altText || ''} title={image.caption || ''} />
            )
          }
        />
        <ErrorBoundary>
          {sections ? (
            <S.SectionContainer>
              <SectionBlocks content={sections} />
            </S.SectionContainer>
          ) : (
            <S.StyledMainContent>
              <Grid>
                {mainEntries && mainEntries.length > 0 && (
                  <S.MainRow>
                    {mainEntries.map((entry) => (
                      <MainEntryCard
                        key={`${entry.title}-${entry.internalLink?.slug}`}
                        entry={entry}
                        col={mainEntriesCol}
                      />
                    ))}
                  </S.MainRow>
                )}
                {secondaryEntries && secondaryEntries.length > 0 && (
                  <S.SectionCol s="12" m="12" l="12" xl="12">
                    <Text tag="h2" variant={TextVariant.Header2}>
                      Hva søker du informasjon om?
                    </Text>
                    <S.SecondaryRow>
                      {secondaryEntries.map((entry) => (
                        <SecondaryEntryCard key={`${entry.name}-${entry.slug}`} entry={entry} />
                      ))}
                    </S.SecondaryRow>
                  </S.SectionCol>
                )}
                {listSection && (
                  <S.SectionCol s="12" m="12" l="12" xl="12">
                    {listSection.title && (
                      <Text tag="h2" variant={TextVariant.Header2}>
                        {listSection.title}
                      </Text>
                    )}
                    {listSection.linkListWithTitleAndIngress && listSection.linkListWithTitleAndIngress.length > 0 && (
                      <S.ListSectionRow>
                        {listSection.linkListWithTitleAndIngress.map((entry, index) => (
                          <NumberedLinkCard key={`${entry.title}`} entry={entry} index={index + 1} />
                        ))}
                      </S.ListSectionRow>
                    )}
                  </S.SectionCol>
                )}
                {featuredBenefitCampaign && featuredBenefitCampaign.length > 0 && (
                  <S.SectionCol s="12" m="12" l="12" xl="12">
                    <FeaturedBenefitBlock block={featuredBenefitCampaign[0]} />
                  </S.SectionCol>
                )}
                {(article1 || article2) && (
                  <S.SectionCol s="12" m="12" l="12" xl="12">
                    <Text tag="h2" variant={TextVariant.Header2}>
                      Aktuelle saker
                    </Text>
                    <S.CardGridRow>
                      {article1 && <ArticleCard article={article1} />}
                      {article2 && <ArticleCard article={article2} />}
                    </S.CardGridRow>
                  </S.SectionCol>
                )}
                {textAndImage && (
                  <S.SectionCol s="12" m="12" l="12" xl="12">
                    <TextAndImageBlock block={textAndImage} />
                  </S.SectionCol>
                )}
                {campaignBlock && (
                  <S.SectionCol s="12" m="12" l="12" xl="12">
                    <CampaignBlock value={campaignBlock} />
                  </S.SectionCol>
                )}
                {benefitTag && <BenefitsByTag tag={benefitTag} />}
              </Grid>
            </S.StyledMainContent>
          )}
        </ErrorBoundary>
      </Layout>
    );
  }
  return null;
};
